<template>
    <sui-message error>
        <sui-message-header v-if="title">{{title}}</sui-message-header>
        <ul v-if="errorList && errorList.length !== 0">
            <li v-for="err in errorList" v-bind:key="err">{{err}}</li>
        </ul>
    </sui-message>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: {
            errorList: Array,
            title: String
        }
    }
</script>

<style scoped>

</style>