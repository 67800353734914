<template>
    <sui-container id="landing">
        <sui-grid vertical-align="middle" columns="two" centered>
            <sui-grid-row>
                <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
                    <img alt="tsaro logo" src="../assets/tsaro-logo.png">
                </sui-grid-column>
                <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
                    <sui-form @submit.prevent="onSubmit" :error="formError" @keyup="formChanged">
                        <sui-form-field>
                            <label for="invitationCode">Invitation Code</label>
                            <input id="invitationCode" type="text" v-model="invitationCode"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label for="email">E-mail</label>
                            <input id="email" type="email" v-model="email"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label for="password">Password</label>
                            <input id="password" type="password" v-model="password"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label for="repassword">Retype Password</label>
                            <input id="repassword" type="password" v-model="rePassword"/>
                        </sui-form-field>
                        <error-message :error-list="formErrorList"></error-message>
                        <sui-button size="medium" color="blue" type="submit">Login</sui-button>
                    </sui-form>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-container>
</template>

<script>
    import axios from "axios"
    import _ from 'lodash'
    import ErrorMessage from "@/components/ErrorMessage"

    import('../css/landing.css')

    export default {
        name: "Register",
        components: {ErrorMessage},
        data: function () {
            return {
                formError: false,
                formErrorList: [],
                invitationCode: this.$route.params.token || "",
                email: "",
                password: "",
                rePassword: "",
            }
        },
        created: function () {
            this.debouncedFormValidate = _.debounce(this.formValidate, 500)
        },
        methods: {
            onSubmit: async function () {
                const showError = () => {
                    this.formError = true;
                    this.formErrorList = [];
                    this.formErrorList.push("Your invitation code is invalid please contact your manager.");
                }
                if (this.formError === false) {
                    try {
                        const response = await axios({
                            "method": "POST",
                            "url": "/api/user/invite",
                            "headers": {
                                "Content-Type": "application/json; charset=utf-8",
                            },
                            "data": {
                                "email": this.email,
                                "invitationCode": this.invitationCode,
                                "password": this.password,
                            }
                        });

                        console.log(response.data);
                        if (Number.isInteger(response.data.userId)) {
                            this.error = false;
                            await this.$router.push("/login");
                            return
                        }
                        showError();
                    } catch (e) {
                        console.log("network error");
                        showError();
                    }
                }
            },
            formChanged: async function () {
                this.debouncedFormValidate()
            },
            formValidate: async function () {
                this.formErrorList = [];
                if (this.password !== this.rePassword) {
                    this.formErrorList.push('Please retype your password correctly');
                }
                if (this.password.length < 8) {
                    this.formErrorList.push('Password must be longer than 8 characters');
                }
                if (this.invitationCode.length < 16) {
                    this.formErrorList.push('You must enter invitation code');
                }
                this.formError = this.formErrorList.length !== 0;
            }
        }
    }
</script>

<style scoped>

</style>
